/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import { renderRichText } from '../helpers/richText';

const AboutPage = ({ data }) => {
  const { title, content, image } = data.contentfulAbout;
  return (
    <Layout>
      <Flex sx={{ flexWrap: 'wrap', mx: `24px` }}>
        <Flex sx={{ flex: ['100%',,, 1], p: ['2rem 2px 1rem',,'2rem'] }}>
          <GatsbyImage loading="lazy" image={image?.gatsbyImageData} alt="" />
        </Flex>
        <Box sx={{ flex: 1, p: ['1rem 2px 2rem',,'2rem'] }}>
          <h2>{title}</h2>
          {content && renderRichText(content)}
        </Box>
      </Flex>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query ($id: String) {
    contentfulAbout(id: { eq: $id }) {
      title
      content {
        raw
      }
      image {
        gatsbyImageData
      }
    }
  }
`;
